const dev = {
    API_URL: "http://localhost:4500",
    WS_API_URL: "ws://localhost:4500",
    PUBLIC_URL: "http://localhost:3000",
    APK_DOWNLOAD: "https://play.google.com/store/apps/details?id=com.vive.mobile"
};

/*const dev = {
    API_URL: "http://54.179.92.204:4500",
    PUBLIC_URL: "http://localhost:3000"
};*/

/*const prod = {
  API_URL: "http://52.77.233.195:4000",
  WS_API_URL: "ws://52.77.233.195:4000",
  PUBLIC_URL: "http://52.77.233.195:3000",
  APK_DOWNLOAD: "https://drive.google.com/file/d/1ohS5MQkuGj03uGSbyDny0AqfREIaHk6F/view?usp=sharing"
};

const prod = {
    API_URL: "https://staging.vive.asia/backend",
    WS_API_URL: "wss://staging.vive.asia/backend",
    PUBLIC_URL: "https://staging.vive.asia"
};*/

const staging = {
  API_URL: "https://api.staging.theviveph.com",
  WS_API_URL: "wss://api.staging.theviveph.com",
  PUBLIC_URL: "https://staging.theviveph.com",
  APK_DOWNLOAD: "https://play.google.com/store/apps/details?id=com.vive.mobile"
};

const prod = {
  API_URL: "https://api.theviveph.com",
  WS_API_URL: "wss://api.theviveph.com",
  PUBLIC_URL: "https://theviveph.com",
  APK_DOWNLOAD: "https://play.google.com/store/apps/details?id=com.vive.mobile"
};

const firebase = {
  apiKey: "AIzaSyA3vRpVNtrX40Yj9w1xZZqC7WScz025QEA",
  authDomain: "vibe-92435.firebaseapp.com",
  projectId: "vibe-92435",
  storageBucket: "vibe-92435.appspot.com",
  messagingSenderId: "1065304123706",
  appId: "1:1065304123706:web:6ea0665df198df639ec803",
  measurementId: "G-GXHTMLVGY7"
};

const config = process.env.NODE_ENV === 'production'
  ? staging
  : dev;

export default {
  // Add common config values here
  CODE_LENGTH: 4,
  PASSWORD_LENGTH: 6,
  MAX_UPLOAD_SIZE: 2,  //MB
  MAX_ATTACHMENT_SIZE: 2,  //MB
  MAX_GALLERY_ALLOWED: 6,
  FIREBASE: firebase,
  SITE_NAME: 'Vive',
  S3_URL: "https://vive-assets.s3.ap-southeast-1.amazonaws.com/",
  FB_URL: "https://www.facebook.com/theviveph",
  IG_URL: "https://instagram.com/theviveph",
  LI_URL: "https://www.linkedin.com/company/theviveph",
  TIK_URL: "https://www.tiktok.com/@theviveph",
  OFFICIAL_EMAIL: " info@theviveph.com",
  footerText: `© ${new Date().getFullYear()} Vive. All Rights Reserved.`,
  ...config
};
